// eslint-disable-next-line
import React from 'react';
import { CardContent, Typography } from '@material-ui/core';
import { PageProps } from '../App';
import PageCard from './page-card';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export default function Contact(props: PageProps) {
	return (
		<>
			<PageCard>
				<CardContent>
					<ArrowUpwardIcon fontSize="large" />
					<Typography variant="h5" align="center">
						Pick your flavor from the icons in the header!
					</Typography>{' '}
				</CardContent>
			</PageCard>
		</>
	);
}
